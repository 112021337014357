export const CATEGORIES = [
  {
    key: "non_tech",
    name: "Non Tech",
  },
  {
    key: "cs_tech",
    name: "CS Tech",
  },
  {
    key: "general_tech",
    name: "Gen Tech",
  },
  {
    key: "other",
    name: "Other",
  },
];

export const EVENT_TYPES = [
  {
    key: "general",
    name: "General",
  },
  {
    key: "competition",
    name: "Competitions",
  },
  {
    key: "workshop",
    name: "Workshops",
  },
  {
    key: "talk",
    name: "Talks",
  },
  {
    key: "conference",
    name: "Conferences",
  },
];

const ExcelAPIConfig = {
  environment: "production",
  baseURL: "https://eventbackend-xgveswperq-uc.a.run.app",
  CONSTANTS: {
    eventCategories: [
      {
        nonTech: "non_tech",
      },
      {
        csTech: "cs_tech",
      },
      {
        genTech: "general_tech",
      },
      {
        other: "other",
      },
    ],
    eventTypes: [
      {
        general: "general",
      },
      {
        competition: "competition",
      },
      {
        workshop: "workshop",
      },
      {
        talk: "talk",
      },
      {
        conference: "conference",
      },
    ],
    eventStatus: [
      {
        key: "yet_to_start",
        name: "Coming Soon",
      },
      {
        key: "started",
        name: "Ongoing",
      },
      {
        key: "finished",
        name: "Over",
      },
    ],
  },
  eventsPath: "/api/events",
  bookmarksPath: "/api/bookmark",
  eventTypePath: (eventType) => `/api/events/type/${eventType}`,
  eventCategoryPath: (eventCategory) => `/api/events/category/${eventCategory}`,
  eventWithTypeAndCategoryPath: (eventType, eventCategory) =>
    `/api/events/event_type=${eventType}&category=${eventCategory}`,
  eventByIdPath: (eventId) => `/api/events/${eventId}`,
};

export default ExcelAPIConfig;
