import React from "react";

import "./Events.css";
import EventCard from "./EventsCard/EventsCard";

import { useExcelEventsData } from "../../api/query";

function Events({ setBookmarkedEvents, bookmarkedEvents, registeredEvents }) {
  const {
    data: eventsData
    // error,
    // isError,
    // isFetched,
    // isFetching,
    // isLoading,
    // isLoadingError,
    // isRefetchError,
    // isRefetching,
    // isSuccess,
  } = useExcelEventsData({ eventType: "general" });
  return (
    <div className="events">
      <div className="events__body">
        <div className="events__card">
          {eventsData &&
            eventsData.sort((a, b) => a.datetime < b.datetime ? 1 : -1).map((event, index) => {
              return (
                <div className="col" key={index}>
                  <EventCard event={event}
                    registeredEvents={registeredEvents}
                    bookmarkedEvents={bookmarkedEvents}
                    setBookmarkedEvents={setBookmarkedEvents}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Events;
