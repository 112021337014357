import React from 'react';

const ExternalWebsite = () => {
  return (
      <iframe
        title="External Website"
        src="https://coming-soon-b7l.pages.dev/"
        width="100%"
        // height="100%"
        style={{
          height:"100vh",
          border:"none"
        }}
      ></iframe>
  );
};

export default ExternalWebsite;
