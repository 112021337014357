import React from 'react'
import Lottie from 'react-lottie';

import success from '../../assets/json/loading.json'
import './Loading.css'

function Loading() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className='loading'>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
      />
    </div>
  )
}

export default Loading