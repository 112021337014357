import React from 'react'
import { ColorRing } from  'react-loader-spinner'

function Loader() {
  return (
    <div className="loader">
        <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#65C2D2', '#187BA2', '#4DBFBE', '#7ACBAF', '#B1D89E']}
        />
    </div>
  )
}

export default Loader